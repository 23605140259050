<template>
  <div class="page">
    <div class="head">
      <div class="headInfo">
        <img :src="info.picture_str" alt="" />
        <div style="width: 200px;">{{ info.goods_name }}</div>
      </div>
      <div class="headSteps">
        <el-steps :active="active" process-status="wait" align-center>
          <el-step title="团长开团" :description="info.start_time"></el-step>
          <el-step title="拼团中"></el-step>
          <el-step
            :title="
              info.status == 3
                ? '拼团成功'
                : info.status == 4
                ? '拼团失败'
                : '拼团成功'
            "
            :description="active == 3 || active == 4 ? info.completion_time : ''"
          ></el-step>
        </el-steps>
      </div>
      <div class="back">
        <el-button
          type="primary"
          style="width: 92px"
          size="medium"
          @click="back"
          >返回
        </el-button>
      </div>
    </div>
    <div class="info">
      <div class="title">
        团购状态:{{
          info.status == 3
            ? "拼团成功"
            : info.status == 4
            ? "拼团失败"
            : "拼团中"
        }}
      </div>
    </div>
    <div class="infoDetails">
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>团购信息</div>
      </div>
      <div class="infoDetailsBox">
        <div class="list_item">团购编码：{{ info.order_group_sn }}</div>
        <div class="list_item">满团标准人数：{{ info.group_num }}人</div>
        <div class="list_item">开团时间：{{ info.start_time }}</div>
        <div class="list_item">截止时间：{{ info.end_time }}</div>
        <div class="list_item" v-if="info.status == 3 || info.status == 4">
          {{ info.status == 3 ? "成团" : "失败" }}时间：{{ info.completion_time }}
        </div>
      </div>
      <div class="lineH-box">
        <div class="lineH"></div>
        <div>参团信息</div>
      </div>
      <div class="infoTable">
        <div class="table-box">
          <el-table
            ref="multipleTable"
            :data="info.item"
            tooltip-effect="dark"
            style="width: 100%; min-width: 1000px"
            :header-cell-style="{ background: '#F5F5F5' }"
          >
            <el-table-column label="用户信息" width="400px" prop="">
              <template slot-scope="scope">
                <div style="display: flex;align-items: center;">
                  <img
                    :src="scope.row.head_img_str"
                    style="width: 60px;height: 60px;border-radius: 50%;margin-right: 5px;"
                    alt=""
                  />
                  <div>{{ scope.row.nick_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="参团时间" width="300px" prop="create_time">
            </el-table-column>
            <el-table-column label="订单编号" min-width="300px" prop="order_sn">
              <template slot-scope="scope">
                <div style="cursor: pointer;color:#4974f5;" @click="toOrder(scope.row.order_id)">
                  {{ scope.row.order_sn }}
                </div>
              </template>
            </el-table-column>
            <el-table-column label="订单状态" min-width="300px" prop="status">
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      active: 0,
      order_group_id: this.$route.query.order_group_id,
      info: {},
    };
  },
  created() {
    this.getDetails();
  },
  methods: {
    getDetails() {
      const data = {
        order_group_id: this.order_group_id,
      };
      this.axios
        .post("/store/Shopactivity/getGroupOrderInfo", data)
        .then((res) => {
          console.log(res);
          this.info = res.data.info;
          this.active = this.info.status == 3 || this.info.status == 4 ? 3 : 2;
          this.info.start_time = res.data.info.start_time
            ? this.dayjs(res.data.info.start_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          this.info.completion_time = res.data.info.completion_time
            ? this.dayjs(res.data.info.completion_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          this.info.end_time = res.data.info.end_time
            ? this.dayjs(res.data.info.end_time * 1000).format(
                "YYYY-MM-DD HH:mm:ss"
              )
            : "";
          this.info.item.map((y) => {
            y.create_time = this.dayjs(y.create_time * 1000).format(
              "YYYY-MM-DD HH:mm:ss"
            );
            //1待付款，2待发货，3待收货，4已完成，5超时取消，6用户取消，7超时未发货取消，8用户申请退款用户申请退款，9商户取消，10拼团未成功取消
            switch (y.status) {
              case 1:
                y.status = "待付款";
                break;

              case 2:
                if(y.is_group_order != 1){
                  y.status = "待发货";
                }else{
                  y.status = "待成团";
                }
                break;
              case 3:
                y.status = "待收货";
                break;
              case 4:
                y.status = "已完成";
                break;
              case 5:
                y.status = "超时取消";
                break;
              case 6:
                y.status = "用户取消";
                break;
              case 7:
                y.status = "超时未发货取消";
                break;
              case 8:
                y.status = "用户申请退款";
                break;
              case 9:
                y.status = "商户取消";
                break;
              case 10:
                y.status = "拼团未成功取消";
                break;
              default:
                break;
            }
            return y;
          });
        });
    },
    back() {
      this.$router.go(-1);
    },
    toOrder(val){
      this.$router.push({
        path: '/order/orderDetail',
        query: {
          order_id:val
        }
        });
    }
  },
};
</script>
<style lang="scss" scoped>
.lineH-box {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 15px;
  color: #333333;
  .lineH {
    width: 3px;
    height: 18px;
    background: #4974f5;
    border-radius: 21px;
    margin-right: 8px;
  }
}
.page {
  .head {
    display: flex;
    align-items: center;
    padding: 20px 0 20px 30px;
    .headInfo {
      width: auto;
      display: flex;
      align-items: center;
      img {
        width: 160px;
        height: 120px;
        margin-right: 20px;
      }
    }
    .headSteps {
      flex: 1;
    }
    .back {
      margin: 0 60px 0 180px;
    }
  }
  .info {
    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      color: rgb(102, 102, 102);
      background-color: rgb(215, 215, 215);
    }
  }
  .infoDetails {
    padding: 20px;
    .infoDetailsBox {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
      .list_item {
        width: 30%;
        margin-right: 20%;
        margin-bottom: 30px;
      }
    }
  }
}
</style>
